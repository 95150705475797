import { defineStore } from "pinia";
import { useRuntimeConfig } from "#app";
import { useAuthStore } from "~/stores/AuthStore";

export const useCourseStore = defineStore("course", {
  state: () => ({
    courses: [],
    upcomingCourses: [],
    course: {},
    coursesList: [],
    categories: [],
    comments: {},
    rates: [],
    notes: {},
    videoPlaybackLocation: 0,
    loading: false,
    relatedProducts: null,
  }),

  actions: {
    async fetch(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        this.loading = true;
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}courses`,
          {
            headers: authStore.auth.headers,
            params: {
              search_text: payload.search_text,
              sort: payload.sort,
              page: payload ? payload.page : null,
              per_page: payload ? payload.per_page : null,
              category: payload ? payload.category : null,
              "filter[has_reduced_price]": payload.all_reduced_courses,
              "filter[is_purchasable]": payload.is_purchasable,
            },
          },
        );
        this.loading = false;

        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        } else {
          if (payload.category === "upcoming_courses") {
            this.upcomingCourses = data.value ? data.value.data : {};
          } else {
            this.courses = data.value ? data.value.data : {};
          }
          if (payload.current_page === 1) {
            this.coursesList = data.value.data.courses;
          } else {
            this.coursesList.push(...data.value.data.courses);
          }
          return data.value;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async fetchCategories() {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}category`,
          {
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }

        this.categories = data.value ? data.value.data : {};
        if (this.categories) {
          this.categories.unshift({ name: "الكل", id: "" });
        }
        return data.value;
      } catch (err) {
        console.error(err);
      }
    },
    async show(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        this.loading = true;
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}courses/${payload.id}`,
          {
            method: "GET",
            headers: authStore.auth.headers,
            params: { view: payload.view },
          },
        );

        this.loading = false;

        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        } else {
          this.course = data.value ? data.value.data : {};
          this.showRelatedProducts({ id: this.course.id });
          return data.value;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async showRelatedProducts(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        this.loading = true;
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}related-products/${payload.id}`,
          {
            method: "GET",
            headers: authStore.auth.headers
          },
        );

        this.loading = false;

        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        } else {
          this.relatedProducts = data.value ? data.value.data : {};
          return data.value;
        }
      } catch (err) {
        console.error(err);
      }
    }, 
    async fetchComments(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}commentable`,
          {
            headers: authStore.auth.headers,
            params: {
              page: payload ? payload.page : null,
              per_page: payload ? payload.per_page : null,
              commentable_id: payload.commentable_id,
              commentable_type: payload.commentable_type,
            },
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.comments = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchRates(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}rateable`,
          {
            headers: authStore.auth.headers,
            params: {
              page: payload ? payload.page : null,
              per_page: payload ? payload.per_page : null,
              rateable_id: payload.rateable_id,
              rateable_type: payload.rateable_type,
            },
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.rates = data.value ? data.value.data : {};
        return data.value.data;
      } catch (err) {
        console.error(err);
      }
    },
    async downloadFile(payload) {
      const authStore = useAuthStore();
      try {
        const downloadUrl = process.env.BASE_URL;
        const material =
          payload.attachmentId === 0
            ? `${downloadUrl}courses/${payload.id}/download-${payload.type}`
            : `${downloadUrl}courses/${payload.id}/download-${payload.type}/${payload.attachmentId}`;
        const response = await useFetch(
          `${useRuntimeConfig().public.baseUrl}${material}`,
          {
            method: "GET",
            headers: authStore.auth.headers,
          },
        );
        const blob = await response.blob();
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", payload.name);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (err) {
        console.error(err);
      }
    },
    async candidateCustomer(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}candidate-customer`,
          {
            headers: authStore.auth.headers,
            method: "POST",
            body: payload,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        console.error(err);
      }
    },
    async updateVideoPlaybackLocation(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}watched-videos`,
          {
            headers: authStore.auth.headers,
            method: "POST",
            body: payload,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        const videoIndex = this.course.videos.findIndex(
          (video) => video.watched_id === data.value.id,
        );
        if (videoIndex !== -1) {
          this.course.videos[videoIndex].stopped_in = data.value.stopped_in;
        }
        return data.value;
      } catch (err) {
        console.error(err);
        return err.response ? err.response.data : {};
      }
    },
    changeItemStatusAfterAddedToCart(courseId) {
      const course = this.coursesList.find((course) => course.id === courseId);
      if (course) {
        const courseIndex = this.coursesList.indexOf(course);
        this.coursesList[courseIndex].pay_button_status = false;
        this.coursesList[courseIndex].pay_button_title = "في السلّة";
      }
    },
    async addComment(formData) {
      const authStore = useAuthStore();
      try {
        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}comments`,
          {
            method: "POST",
            body: formData,
            headers: authStore.auth.headers,
          },
        );
        return data.value;
      } catch (e) {
        return e.response ? e.response.data : {};
      }
    },

    async addRate(formData) {
      const authStore = useAuthStore();
      try {
        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}rates`,
          {
            method: "POST",
            body: formData,
            headers: authStore.auth.headers,
          },
        );
        return data.value;
      } catch (e) {
        return e.response ? e.response.data : {};
      }
    },

    async laterRate(formData) {
      const authStore = useAuthStore();
      try {
        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}complete-course-videos-mail`,
          {
            method: "POST",
            body: formData,
            headers: authStore.auth.headers,
          },
        );
        return data.value;
      } catch (e) {
        return e.response ? e.response.data : {};
      }
    },

    async addNote(formData) {
      const authStore = useAuthStore();
      try {
        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}notes`,
          {
            method: "POST",
            body: formData,
            headers: authStore.auth.headers,
          },
        );
        return data.value;
      } catch (e) {
        return e.response ? e.response.data : {};
      }
    },

    async getNotes(id) {
      const authStore = useAuthStore();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}videos/${id}/notes`,
          {
            method: "GET",
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          return error.value;
        } else {
          this.notes = data.value.data;
          return data.value;
        }
      } catch (e) {
        return e.response ? e.response.data : {};
      }
    },

    async deleteNote(id) {
      const authStore = useAuthStore();
      try {
        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}notes`,
          {
            method: "DELETE",
            params: { ids: id },
            headers: authStore.auth.headers,
          },
        );
        return data.value;
      } catch (e) {
        return e.response ? e.response.data : {};
      }
    },

    async addFavorite(formData) {
      const authStore = useAuthStore();
      try {
        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}favorites`,
          {
            method: "POST",
            body: formData,
            headers: authStore.auth.headers,
          },
        );
        return data.value;
      } catch (e) {
        return e.response ? e.response.data : {};
      }
    },

    async deleteFavorite(id) {
      const authStore = useAuthStore();
      try {
        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}favorites`,
          {
            method: "DELETE",
            params: { ids: id },
            headers: authStore.auth.headers,
          },
        );
        return data.value;
      } catch (e) {
        return e.response ? e.response.data : {};
      }
    },

    async isWatchedVideo(data) {
      const authStore = useAuthStore();

      const { error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}watched-videos`,
        {
          method: "POST",
          body: data,
          headers: authStore.auth.headers,
        },
      );
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return false;
      } else {
        return true;
      }
    },

    async addToSubscriptions(formData) {
      const authStore = useAuthStore();
      try {
        const { data } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}subscriptions`,
          {
            method: "POST",
            body: formData,
            headers: authStore.auth.headers,
          },
        );
        return data.value;
      } catch (e) {
        return e.response ? e.response.data : {};
      }
    },
  },
});
